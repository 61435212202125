import useSWR from "swr";
import { swrCommandConfig } from "configs/swrConfig";
import { useAppContext } from "hooks/useAppContext";
import { useFormik } from "formik";
import * as yup from "yup";

const useGame = () => {
  const context = useAppContext();

  const fetcher = async (url: string) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formik.values),
    });

    var token = await response.json();

    context.setName(formik.values.player);
    context.setBalance(formik.values.balance);

    context.setToken(token);
    context.setStart(true);
  };

  const { error, isValidating, mutate } = useSWR(`${process.env.REACT_APP_API_URL}/operator/create-session`, fetcher, swrCommandConfig);

  const validationSchema = yup.object({
    player: yup.string().required("Player is required"),
    balance: yup.number().required("Balance must be at least 1$").min(1),
  });

  const formik = useFormik({
    initialValues: {
      player: "",
      balance: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setTimeout(() => {
        mutate();
      }, 250);
    },
  });

  return { context, formik, error, isValidating };
};

export default useGame;
