import GameComponent from "components/GameComponent";
import { useAppContext } from "hooks/useAppContext";
import GameRoundComponent from "./GameRoundComponent";

const GameRouterComponent = () => {
  const context = useAppContext();
  return <div>{context.start ? <GameRoundComponent /> : <GameComponent />}</div>;
};

export default GameRouterComponent;
