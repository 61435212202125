import "./App.css";
import RouterComponent from "components/RouterComponent";
import { AppContextProvider } from "hooks/useAppContext";
import { useState } from "react";

function App() {
  const [start, setStart] = useState(false);
  const [balance, setBalance] = useState(0);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  return (
    <div className="App">
      <AppContextProvider context={{ start, setStart, balance, setBalance, name, setName, token, setToken }}>{<RouterComponent />} </AppContextProvider>
    </div>
  );
}

export default App;
