// signalRService.js
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import moment from "moment";

const signalRService = {
  connection: {} as HubConnection,
  startConnection: (hubUrl: string) => {
    signalRService.connection = new HubConnectionBuilder().withUrl(hubUrl).build();

    signalRService.connection
      .start()
      .then(() => {
        console.log("SignalR connection established.", moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ"));
      })
      .catch((err) => {
        console.error("Error starting SignalR connection:", err);
      });
  },

  onReceiveMessage: (callback: (arg0: string) => void) => {
    signalRService.connection.on("DataTransfer", (json) => {
      callback(json);
    });
  },

  onReceiveStart: (callback: (arg0: string) => void) => {
    signalRService.connection.on("StartGameRound", (json) => {
      callback(json);
    });
  },

  onReceiveEnd: (callback: (arg0: string) => void) => {
    signalRService.connection.on("EndGameRound", (json) => {
      callback(json);
    });
  },

  onReceiveBet: (callback: (arg0: string) => void) => {
    signalRService.connection.on("Bet", (json) => {
      callback(json);
    });
  },

  onReceiveCashout: (callback: (arg0: string) => void) => {
    signalRService.connection.on("CashOut", (json) => {
      callback(json);
    });
  },

  onReceiveGameRound: (callback: (arg0: string) => void) => {
    signalRService.connection.on("GameRound", (json) => {
      callback(json);
    });
  },

  sendMessage: (json: string) => {
    signalRService.connection
      .invoke("DataTransfer", json)
      .then(() => console.info("Message sent:", json))
      .catch((err) => {
        console.error("Error sending message:", err);
      });
  },

  sendBet: (json: string) => {
    signalRService.connection
      .invoke("Bet", json)
      .then(() => console.info("Message sent:", json))
      .catch((err) => {
        console.error("Error sending message:", err);
      });
  },

  sendCashout: (json: string) => {
    signalRService.connection
      .invoke("CashOut", json)
      .then(() => console.info("Message sent:", json))
      .catch((err) => {
        console.error("Error sending message:", err);
      });
  },
};

export default signalRService;
