import React, { createContext, Dispatch, SetStateAction, useContext } from "react";

interface AppContext {
  start: boolean;
  setStart: Dispatch<SetStateAction<boolean>>;
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
}

type Props = {
  context: AppContext;
  children?: React.ReactNode;
};

const AppContextProvider = ({ context, children }: Props) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

const AppContext = createContext<AppContext>({} as AppContext);

const useAppContext = (): AppContext => {
  const stepContext = useContext<AppContext>(AppContext);
  if (stepContext === undefined) {
    throw new Error("useAppContext must be used inside AppContext.Provider");
  }
  return stepContext;
};

export { AppContextProvider, AppContext, useAppContext };
