import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import GameRoundsComponent from "./data-tables/GameRoundsComponent";
import GameRouterComponent from "./GameRouterComponent";
import OperatorComponent from "./data-tables/OperatorsComponent";
import PlayersComponent from "./data-tables/PlayersComponent";
import SessionsComponent from "./data-tables/SessionsComponent";
import SignalRComponent from "./SignalRComponent";
import TransactionsComponent from "./data-tables/TransactionsComponent";

const RouterComponent = () => {
  const [selectedValue, setSelectedValue] = useState("game");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Select functionality</FormLabel>
        <Divider orientation="vertical" variant="middle" flexItem />
        <RadioGroup row onChange={handleChange} aria-labelledby="demo-radio-buttons-group-label" defaultValue={selectedValue} name="row-radio-buttons-group">
          <FormControlLabel value="game" control={<Radio />} label="Test game" />
          <FormControlLabel value="players" control={<Radio />} label="BO Players" />
          <FormControlLabel value="operators" control={<Radio />} label="BO Operators" />
          <FormControlLabel value="sessions" control={<Radio />} label="BO Sessions" />
          <FormControlLabel value="gameRounds" control={<Radio />} label="BO GameRounds" />
          <FormControlLabel value="transactions" control={<Radio />} label="BO Transactions" />
          <FormControlLabel value="connection" control={<Radio />} label="Test connection" />
        </RadioGroup>
      </FormControl>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Divider orientation="horizontal" flexItem />
      <Divider orientation="vertical" variant="middle" flexItem />
      {selectedValue === "game" && <GameRouterComponent />}
      <br />
      {selectedValue === "players" && <PlayersComponent />}
      <br />
      {selectedValue === "operators" && <OperatorComponent />}
      <br />
      {selectedValue === "sessions" && <SessionsComponent />}
      <br />
      {selectedValue === "gameRounds" && <GameRoundsComponent />}
      <br />
      {selectedValue === "transactions" && <TransactionsComponent />}
      <br />
      {selectedValue === "connection" && <SignalRComponent />}
    </div>
  );
};

export default RouterComponent;
