import { Button, CircularProgress, Divider, Grid, TextField } from "@mui/material";
import useGameRound from "hooks/useGameRound";

const GameRoundComponent = () => {
  const { seconds, cashoutReceived, betReceived, start, win, stake, messages, multiplier, history, buttonBetDisabled, buttonCashoutDisabled, stakeDisabled, clickButtonBet, clickButtonCashout, changeStake, context } = useGameRound();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid item xs={12} gap={2}>
            <h2>Player - {context.name}</h2>
            <h3>Balance - {context.balance}</h3>

            {betReceived && <h3 style={{ color: "blue" }}>Bet received - {stake}</h3>}

            {start === false && (
              <>
                <h2> Last multiplier - {multiplier}x</h2>
                <h1 style={{ color: "blue" }}>Waiting for new round</h1>
                <h1>{seconds}</h1>
                <CircularProgress />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Divider orientation="horizontal" flexItem />
              </>
            )}
            {start && (
              <>
                <h1> {multiplier}x</h1>
              </>
            )}
            {cashoutReceived && <h1 style={{ color: "green" }}>WIN {win}</h1>}
            <Divider orientation="vertical" variant="middle" flexItem />
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid container xs={12} gap={2}>
            <Grid xs={12}>
              <TextField disabled={stakeDisabled} type="number" onChange={(e) => changeStake(e.target.value)} id="outlined-disabled" label="Stake" defaultValue="Your Stake" value={stake === 0 ? "" : stake} />
            </Grid>
            <Grid xs={12}>
              <Button disabled={buttonBetDisabled} onClick={clickButtonBet} variant="contained">
                BET
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Button disabled={buttonCashoutDisabled} onClick={clickButtonCashout} variant="contained">
                CASHOUT
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <h2>LOG</h2>
          {messages.sort().map((msg, index) => (
            <div key={index}>
              <br />
              <strong>{msg}</strong> <br />
            </div>
          ))}
        </Grid>
        <Grid item xs={4}>
          <h2>Game History</h2>
          {history
            .sort((data) => data.time)
            .map((msg, index) => (
              <div key={index}>
                <h3>GameRound {index}</h3>
                <strong>GameRoundId : {msg.gameRoundId}</strong> <br />
                <strong>Final Multiplier : {msg.multiplier}</strong> <br />
                <strong>Time : {msg.time}</strong> <br />
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default GameRoundComponent;
