import { GameRound } from "interfaces/interfaces";
import { useEffect, useState } from "react";
import signalRService from "services/signalRService";
import { useAppContext } from "hooks/useAppContext";
import useCountdown from "hooks/useCountdown";
import { isFalsy } from "utility-types";

const useGameRound = () => {
  const context = useAppContext();
  const [messages, setMessages] = useState<string[]>([]);
  const [multiplier, setMultiplier] = useState(0);
  const [gameRound, setGameRound] = useState<GameRound>({} as GameRound);
  const [history, setHistory] = useState<GameRound[]>([]);
  const [stake, setStake] = useState<number>(0);
  const [bet, setBet] = useState<boolean>(false);
  const [buttonBetDisabled, setButtonBetDisabled] = useState<boolean>(true);
  const [buttonCashoutDisabled, setButtonCashoutDisabled] = useState<boolean>(true);
  const [stakeDisabled, setStakeDisabled] = useState<boolean>(true);
  const [win, setWin] = useState<number>(0);
  const [start, setStart] = useState<boolean>(false);
  const [betReceived, setBetReceived] = useState<boolean>(false);
  const [cashoutReceived, setCashoutReceived] = useState<boolean>(false);

  let { seconds, setCountdown } = useCountdown();

  useEffect(() => {
    signalRService.onReceiveBet((receivedJson: string) => {
      setMessages([...messages, receivedJson]);
      setBetReceived(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveCashout((receivedJson: string) => {
      setMessages([...messages, receivedJson]);
      setCashoutReceived(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveStart((receivedJson: string) => {
      setCountdown(0);
      setMessages([...messages, receivedJson]);
      setButtonBetDisabled(true);
      setButtonCashoutDisabled(isFalsy(bet));
      setStakeDisabled(true);
      setStart(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveEnd((receivedJson: string) => {
      setButtonCashoutDisabled(true);
      setStakeDisabled(false);
      setStake(0);
      setCashoutReceived(false);
      setWin(0);
      setStart(false);
      setBetReceived(false);
      setCountdown(Date.now() + 30000);
      setMessages([]);

      let object: GameRound = JSON.parse(receivedJson);
      setHistory([...history, object]);
    });
  });

  useEffect(() => {
    signalRService.onReceiveGameRound((receivedJson: string) => {
      let object: GameRound = JSON.parse(receivedJson);

      setMultiplier(object.multiplier);
      setMessages([...messages, receivedJson]);

      if (gameRound.gameRoundId !== object.gameRoundId) {
        setGameRound(object);
      }
    });
  }, [messages]);

  const clickButtonBet = () => {
    setBet(true);
    setButtonBetDisabled(true);
    setStakeDisabled(true);
    let payload = JSON.stringify({ amount: stake, clientTime: Date.now(), token: context.token });

    signalRService.sendBet(payload);

    context.setBalance(context.balance - stake);
  };

  const clickButtonCashout = () => {
    let win = multiplier * stake;

    let payload = JSON.stringify({ clientTime: Date.now(), amount: win, token: context.token });

    signalRService.sendCashout(payload);

    setWin(win);
    context.setBalance(context.balance + win);

    setButtonCashoutDisabled(true);
  };

  const changeStake = (data: string) => {
    let stake = Number(data);

    setStake(stake);

    if (stake > 0) {
      setButtonBetDisabled(false);
    }

    if (stake > context.balance) {
      setButtonBetDisabled(true);
    } else {
      setButtonBetDisabled(false);
    }
  };

  return { seconds, cashoutReceived, betReceived, start, win, bet, stake, messages, multiplier, history, buttonBetDisabled, buttonCashoutDisabled, stakeDisabled, clickButtonBet, clickButtonCashout, changeStake, context };
};

export default useGameRound;
